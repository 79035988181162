import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import markerSDK from "@marker.io/browser";
import { filter, skip, Subscription } from "rxjs";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";

import { BulkAddSlideOverModel } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.model";
import { BulkAddSlideOverService } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.service";
import {
  AuthUserTypeEnum,
  EnvironmentEnum,
  InvitationStatusEnum,
  InboundShareStatusEnum,
  FeatureFlagEnum,
} from "@shared/enums";
import { IAvailableOrganisation, IConfig, IUser, IUserData } from "@shared/interfaces";
import {
  AuthenticationService,
  CommonService,
  DownloadDocumentsService,
  ErrorReportingService,
  FeatureFlagService,
  InvitationsService,
  RecordSharingService,
  UsersService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { APP_CONFIG } from "@shared/tokens";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnDestroy, AfterViewInit {
  @HostListener("window:beforeunload")
  canDeactivate(): boolean {
    return !this.downloadDocumentsService.isDownloadingDocuments();
  }

  public readonly environmentName: string = this.environment.name;

  public isLoggedIn = false;

  public isShowSideMenuAndNavRoute = true;

  public userData: IUserData = null;

  public activeOrganisation: IAvailableOrganisation = null;

  public currentUrl = "";

  public pendingInvitationsCount = 0;

  public newInboundSharesCount = 0;

  public userName: string = null;

  public userType: AuthUserTypeEnum;

  public isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
  );

  public isAccountOwnerOrAdminOrContributor =
    this.authenticationService.isAccountOwnerOrAdminOrContributor();

  public isAdminMenuEnabled = this.authenticationService.canGoToAdminSection();

  public canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public isSharedUser = this.authenticationService.isSharedUser();

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private invitationsService: InvitationsService,
    private usersService: UsersService,
    @Inject(APP_CONFIG) private environment: IConfig,
    private overlay: SlideOverlayPageService,
    private bulkAddService: BulkAddSlideOverService,
    private downloadDocumentsService: DownloadDocumentsService,
    private featureFlagService: FeatureFlagService,
    private recordSharingService: RecordSharingService,
    private routerService: RouterService,
    private errorReportingService: ErrorReportingService,
  ) {
    console.log(`Environment: ${this.environmentName}`);

    this.subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(async () => {
          this.currentUrl = this.router.routerState.snapshot.url;
          this.isShowSideMenuAndNavRoute = !this.currentUrl.startsWith("/documents/view");
          this.isLoggedIn = !this.authenticationService.isLocked();

          if (this.isLoggedIn) {
            this.routerService.addHistory(this.currentUrl);
          } else {
            this.routerService.clearHistory();
          }
        }),
    );

    this.subscriptions.add(
      this.authenticationService.userDataObservable$.subscribe((userData: IUserData) => {
        this.onUserDataChanged(userData);
        this.errorReportingService.setUserContext(userData);
      }),
    );

    this.subscriptions.add(
      this.invitationsService.pendingInvitationsCountObservable$.subscribe(
        (pendingInvitationsCount: number) => {
          this.pendingInvitationsCount = pendingInvitationsCount;
        },
      ),
    );

    this.subscriptions.add(
      this.recordSharingService.newInboundSharesCountObservable$.subscribe(
        (newInboundSharesCount: number) => {
          this.newInboundSharesCount = newInboundSharesCount;
        },
      ),
    );
  }

  async ngAfterViewInit(): Promise<void> {
    this.subscriptions.add(
      this.route.queryParams.pipe(skip(1)).subscribe((params) => {
        if (this.overlay.canDeactivateInProgress()) {
          this.overlay.canDeactivateInProgress.set(false);

          return;
        }

        if (this.bulkAddService.canDeactivateInProgress()) {
          this.bulkAddService.canDeactivateInProgress.set(false);

          return;
        }

        this.overlay.toggle(params["view"], params["id"]);
        this.bulkAddService.toggle(params["bulkAdd"] as BulkAddSlideOverModel.ResourceTypeEnum);
      }),
    );

    if (this.environmentName === EnvironmentEnum.UAT && this.environment.marketProjectId) {
      await markerSDK.loadWidget({
        project: this.environment.marketProjectId,
        source: "snippet",
      });
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private onUserDataChanged = (userData: IUserData): void => {
    this.activeOrganisation = null;
    this.pendingInvitationsCount = 0;
    this.newInboundSharesCount = 0;
    this.userName = null;
    this.userData = userData;

    if (userData) {
      this.isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
        FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
      );
      this.isAccountOwnerOrAdminOrContributor =
        this.authenticationService.isAccountOwnerOrAdminOrContributor();
      this.isAdminMenuEnabled = this.authenticationService.canGoToAdminSection();
      this.canAddModifyEntities = this.authenticationService.canAddModifyEntities();
      this.isSharedUser = this.authenticationService.isSharedUser();
      this.userType = this.authenticationService.getUserType();
      switch (this.userType) {
        case AuthUserTypeEnum.REGULAR:
          this.invitationsService.getPage(userData.email, undefined, InvitationStatusEnum.PENDING);
          this.usersService.get(userData.id).then((user: IUser) => {
            this.userName = `${user.firstName} ${user.lastName}`;
          });
          if (this.isCrossOrgSharingEnabled && this.isAccountOwnerOrAdminOrContributor) {
            this.recordSharingService.getAllInboundShares(InboundShareStatusEnum.NEW);
          }
          if (userData.availableOrganisations?.length) {
            if (userData.activeOrganisationIndex !== null) {
              this.activeOrganisation =
                userData.availableOrganisations[userData.activeOrganisationIndex];
            } else {
              this.activeOrganisation = userData.availableOrganisations[0];
            }
          }
          break;
      }

      this.commonService.loadUnitOfMeasurements();
    }
  };
}
