import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BehaviorSubject,
  catchError,
  EMPTY,
  filter,
  finalize,
  Observable,
  switchMap,
  take,
  throwError,
} from "rxjs";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";

import { AuthUserTypeEnum, StorageKeyEnum } from "../enums";
import { ITokenResponse } from "../interfaces";
import { AuthenticationService, AuthService } from "../services";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;

  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private overlay: SlideOverlayPageService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authenticationService.getToken(StorageKeyEnum.ACCESS_TOKEN);

    if (accessToken && this.doesRequestNeedsToken(request)) {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          this.doesRequestNeedsToken(request)
        ) {
          if (this.authenticationService.haveTokensExpired()) {
            this.notificationService.showError(CommonConstants.SESSION_HAS_EXPIRED_TEXT);
            this.overlay.close(true);
            this.authenticationService.logout();

            return EMPTY;
          } else {
            const userType = this.authenticationService.getUserType();

            switch (userType) {
              case AuthUserTypeEnum.REGULAR: {
                return this.handleUnauthorised(request, next);
              }
            }
          }
        }

        return throwError(() => error);
      }),
    );
  }

  private handleUnauthorised(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.accessTokenSubject.next(null);

      const refreshToken = this.authenticationService.getToken(StorageKeyEnum.REFRESH_TOKEN);

      return this.authService.refreshToken(refreshToken).pipe(
        switchMap((response: ITokenResponse) => {
          this.authenticationService.setTokens(
            AuthUserTypeEnum.REGULAR,
            response.access_token,
            response.refresh_token,
          );
          request = request.clone({
            setHeaders: {
              authorization: `Bearer ${response.access_token}`,
            },
          });
          this.accessTokenSubject.next(response.access_token);

          return next.handle(request);
        }),
        catchError((error) => {
          this.notificationService.showError(CommonConstants.SESSION_HAS_EXPIRED_TEXT);
          this.overlay.close(true);
          this.authenticationService.logout();

          return throwError(() => error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    }

    return this.accessTokenSubject.pipe(
      filter((accessToken) => accessToken != null),
      take(1),
      switchMap((accessToken) => {
        request = request.clone({
          setHeaders: {
            authorization: `Bearer ${accessToken}`,
          },
        });

        return next.handle(request);
      }),
    );
  }

  private doesRequestNeedsToken = (request: HttpRequest<any>): boolean => {
    //todo improve?
    const noAccessTokenUrls = ["auth/token", "common/countries"];

    if (noAccessTokenUrls.some((url) => request.url.includes(url))) {
      return false;
    }

    return true;
  };
}
