<div [attr.data-environment]="environmentName">
  @if (isLoggedIn && userData && isShowSideMenuAndNavRoute) {
    <iov-sidebar-menu
      [isCrossOrgSharingEnabled]="isCrossOrgSharingEnabled"
      [isAccountOwnerOrAdminOrContributor]="isAccountOwnerOrAdminOrContributor"
      [isSharedUser]="isSharedUser"
      [isAdminMenuEnabled]="isAdminMenuEnabled"
      [canAddModifyEntities]="canAddModifyEntities"
      [currentOrganisation]="activeOrganisation"
      [currentUrl]="currentUrl"
      [pendingInvitationsCount]="pendingInvitationsCount"
      [newInboundSharesCount]="newInboundSharesCount"
      [userData]="userData"
      [userName]="userName"
      [userType]="userType"
    ></iov-sidebar-menu>

    <iov-slide-overlay-page></iov-slide-overlay-page>

    <app-bulk-add-slide-over></app-bulk-add-slide-over>
  } @else {
    <router-outlet></router-outlet>
  }
</div>
