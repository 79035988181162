import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
// import { bootstrapApplication, provideClientHydration } from "@angular/platform-browser";

import * as Sentry from "@sentry/angular";

import { APP_CONFIG } from "@shared/tokens";

import { AppModule } from "./app/app.module";
import { IConfig } from "./app/shared/interfaces";

fetch("./assets/config/config.json")
  .then((res) => res.json())
  .then((config: IConfig) => {
    if (config.sentryDsn) {
      Sentry.init({
        // beforeSend: (event) => {
        //   // Avoid sending common errors like 400, 401, and 403 to Sentry
        //   if (event.exception) {
        //     const exception = event.exception.values?.[0];

        //     if (exception) {
        //       const statusCode = exception.value.match(/\b(400|401|403)\b/);

        //       if (statusCode) {
        //         return null;
        //       }
        //     }
        //   }

        //   return event;
        // },
        dsn: config.sentryDsn,
        environment: config.name,
        integrations: [
          // Registers and configures the Tracing integration,
          // which automatically instruments your application to monitor its
          // performance, including custom Angular routing instrumentation
          Sentry.browserTracingIntegration(),
          // Registers the Replay integration,
          // which automatically captures Session Replays
          Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: config.production ? 1.0 : 0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [
          "https://app.dev.interu.io",
          "https://app.staging.interu.io",
          "https://app.uat.interu.io",
          "https://app.interu.io",
        ],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }

    return platformBrowserDynamic([
      {
        provide: APP_CONFIG,
        useValue: config,
        multi: false,
      },
    ]).bootstrapModule(AppModule);
  })
  .catch((err) => console.error(err));
//todo
// bootstrapApplication(RootCmp, {
//     providers: [provideClientHydration()],
// });
